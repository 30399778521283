import styled from 'styled-components';

export const PaginationWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 40px;

  > p {
    font-family: 'Sora', sans-serif;
    font-weight: bold;
    color: #2A5072;
  }

  > #link {
    margin-left: -19px;

    .page {
      cursor: pointer;
      width: 100%;
      margin-left: 20px;
      width: 145px;
      height: 45px;
      border-radius: 6px;
      background: #2A5072;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background;
      &:hover {
        background: #F58634;
      }
      &:focus {
        outline: none;
      }
    }
  }
`;