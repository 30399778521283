import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Wrapper = styled.div`
  padding: 20px 50px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    padding: 20px 40px;
  }

  @media (max-width: 300px) {
    padding: 20px 10px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  color: #044D75;
  font-family: 'Sora', sans-serif;
  font-size: 40px;
  padding: auto;

  > span {
    color: #EE7600;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }

  @media (max-width: 300px) {
    font-size: 26px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  margin-top: 40px;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const Card = styled.div`
  background: #fff;
  padding: 20px 50px;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > img {
    margin-bottom: 20px;
    max-width: 250px;
    max-height: 77px;
    width: auto;
    height: auto;
    align-self: center;
  }
`;

export const CardButton = styled(AniLink)`
  background: #EE7600;
  padding: 14px;
  font-family: 'Sora', sans-serif;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: #2A5072;
  }
`;