import React from 'react';

import { PaginationWrapper } from './styles';

export default function Pagination({
  isFirst,
  isLast,
  currentPage,
  numPages,
  prevPage,
  nextPage
}) {
  return (
    <PaginationWrapper>
      {!isFirst &&
        <a id="link" href={prevPage}>
          <button className="page">← página anterior</button>
        </a>
      }

      <p>{currentPage} de {numPages}</p>

      {!isLast &&
        <a id="link" href={nextPage}>
          <button className="page">proxima página →</button>
        </a>
      }
    </PaginationWrapper>
  );
};
