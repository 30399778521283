import React from 'react';
import { graphql } from 'gatsby';

import SEO from "../../../components/seo";
import Header from '../../../components/Header';
import KnowledgeCenter from '../../../components/KnowledgeCenter';
import Footer from '../../../components/Footer';
import GoTop from '../../../components/GoTop';
import GoTopMobile from '../../../components/GoTopMobile';
import Pagination from '../../../components/Pagination';

import GlobalStyles from '../../../styles/GlobalStyles';

import {
  Wrapper,
  Title,
  CardContainer,
  Card,
  CardButton,
} from './styles';

const CarriersHome = ({ data, pageContext }) => {
  const carriers = data.allStrapiCarrier.edges;
  const { currentPage, numCarriersPage } = pageContext;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numCarriersPage;

  const prevPage = currentPage - 1 === 1
    ? `/${carriers[0].node.class.slug}/#carriers`
    : `/${carriers[0].node.class.slug}/${currentPage - 1}/#carriers`
    ;

  const nextPage = `/${carriers[0].node.class.slug}/${currentPage + 1}/#carriers`;

  return (
    <>
      <SEO
        title={`SmartEnvios: Transportadoras Parceiras`}
      />
      <Header />
      <KnowledgeCenter />

      <Wrapper id="carriers">
        <Title>TRANSPORTADORAS <span>PARCEIRAS</span></Title>

        <CardContainer>
          {carriers.map(carrier => (
            <Card key={carrier.node.id}>
              <img
                alt={`Logo da transportadora ${carrier.node.title}, parceira da SmartEnvios.`}
                src={
                  carrier.node.logo.internal.description
                    .slice(6)
                    .replace(/"/, '')
                }
              />
              <CardButton
                cover
                bg="#fff"
                to={`/${carrier.node.class.slug}/${carrier.node.slug}`}
              >
                Ver políticas
              </CardButton>
            </Card>
          ))}

        </CardContainer>

      </Wrapper>

      <Pagination
        currentPage={currentPage}
        isFirst={isFirst}
        isLast={isLast}
        nextPage={nextPage}
        prevPage={prevPage}
        numPages={numCarriersPage}
      />

      <Footer />
      <GoTop />
      <GoTopMobile />
      <GlobalStyles />
    </>
  );
}

export default CarriersHome;

export const query = graphql`
  query AllCarriers($skip: Int!, $limit: Int!) {
    allStrapiCarrier(
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          id: strapiId
          class {
            slug
          }
          logo {
            internal {
              description
            }
          }
        }
      }
    }
  }
`;